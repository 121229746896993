import React from 'react'
import { IndexRoute, Route } from 'react-router'

import App from './App'
import Introduction from './Introduction'
import Login from './Login'
import ResetPassword from './ResetPassword'
import Signup from './Signup'
import PageNotFound from '../../../../common/components/PageNotFound'

export default (
  <div>
    {/* App routes */}
    <Route path='/' component={App}>
      <IndexRoute component={Introduction} />
      <Route path='sign_up' component={Signup} />
      <Route path='sign_in'>
        <IndexRoute component={Login} />
        <Route path='reset_password' component={ResetPassword} />
      </Route>
    </Route>

    {/* NotFound route */}
    <Route path='*' component={PageNotFound} />
  </div>
)
