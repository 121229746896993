import React from 'react'

const PageNotFound = () => {
  return (
    <div style={{ height: '100vh', margin: 0, padding: 0 }}>
      <iframe
        src='/404.html'
        title='404 Not Found'
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      ></iframe>
    </div>
  )
}

export default PageNotFound
